<template>
    <div class="rs-display-container">
        <div class="content">
            <div class="card">
                <div style="display: flex">
                    <div class="search-bar" style="flex: 1">
                        <input class="input" v-model="queryParams.search" placeholder="请输入作者姓名" type="search" />
                    </div>
                    <div class="search-bar" style="flex: 1; margin-left: 10px">
                        <input class="input" v-model="queryParams.worksName" placeholder="请输入作品名称" type="search" />
                    </div>
                </div>
                <div class="search-bar" style="flex: 1; margin-top: 10px">
                    <input class="input" v-model="queryParams.recommendCompany" placeholder="请输入指导学校/机构"
                        type="search" />
                </div>
                <div class="button" @click="onSearch">搜索</div>
                <van-tabs v-model:active="queryParams.type" type="card" color="#56b0fe" style="margin-top: 20px"
                    @change="onTabsChange">
                    <van-tab title="视频作品" :name="1"></van-tab>
                    <van-tab title="文字作品" :name="2"></van-tab>
                </van-tabs>
                <van-tabs color="#56b0fe" v-model:active="queryParams.age" @change="onTabsChange">
                    <van-tab v-for="(item, index) in ageList" :key="index" :name="item.name"
                        :title="item.title"></van-tab>
                </van-tabs>
                <div style="margin-top: 20px">
                    <span style="color: #56b0fe; font-size: 24px; font-weight: bold">共有{{ total }}个作品</span>
                </div>
                <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoadMore">
                    <div class="list">
                        <VideoItem :item="item" v-for="(item, index) in list" :key="index"
                            @click="toVideoDetail(item, index)" />
                    </div>
                </van-list>
            </div>
        </div>
        <van-popup v-model:show="showPicker" position="bottom">
            <van-picker :columns="areaList" @confirm="onPickerConfirm" @cancel="showPicker = false" item-height="80" />
        </van-popup>
    </div>
</template>

<script>
import VideoItem from "./components/videoItem-a"
import api_activity from "@/assets/api/api_activity"
import { Notify } from "vant"
import { AuthMixin } from "@/assets/utils/mixin"

export default {
    mixins: [AuthMixin],
    name: "RS2024Display",
    components: { VideoItem },
    data() {
        return {
            queryParams: { periodId: 3, search: "", type: 1, start: 0, length: 10, worksName: "", recommendCompany: "", age: '小学低龄组（7-9岁）' },
            hasMroe: true,
            list: [],
            total: 0,
            loading: false,
            finished: false,
            showPicker: false,
            ageList: [
                {
                    name: "小学低龄组（7-9岁）",
                    title: "小学低龄组"
                },
                {
                    name: "小学龄组（10-12岁）",
                    title: "小学龄组"
                },
                {
                    name: "初中组（13-15岁）",
                    title: "初中组"
                }
            ],
        }
    },
    methods: {
        // 刷新数据
        refreshData() {
            this.queryParams.start = 0
            this.queryParams.search = ""
            this.queryParams.recommendCompany = ""
            this.queryParams.worksName = ""
            this.hasMroe = true
            this.getList()
        },
        // 上拉加载更多
        onLoadMore() {
            this.getList()
        },
        // 获取数据
        getList() {
            if (!this.hasMroe) {
                return
            }
            const params = Object.assign({}, this.queryParams)
            api_activity.getReadStoryWorks(params).then((res) => {
                this.loading = false
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg })
                    return
                }
                if (params.start === 0) {
                    this.list = res.data?.rows || []
                } else {
                    this.list = this.list.concat(res.data?.rows || [])
                }
                this.finished = this.list.length >= res.data?.count || 0
                this.hasMroe = res.data?.count || 0 > this.list.length
                this.total = res.data?.count || 0
                this.queryParams.start += 10
            })
        },
        // tabs改变
        onTabsChange() {
            this.queryParams.start = 0
            this.hasMroe = true
            this.getList()
        },
        // 跳转到视频详情中
        toVideoDetail(item, index) {
            const query = { ...this.queryParams, index }
            delete query.start
            delete query.length
            if (item.type === 1) {
                this.$router.push({ path: "/activity/carnival/video-detail", query })
            } else if (item.type === 2) {
                this.$router.push({ path: "/activity/carnival/article-detail", query })
            }
        },
        // 搜索
        onSearch() {
            this.queryParams.start = 0
            this.hasMroe = true
            this.getList()
        },
    },
    mounted() {
        this.initWxConfig({
            itemName: "“红色故事发声人”作品展示",
            desc: "“红色故事发声人”作品展示",
            pic: "https://image2.cqcb.com/d/file/image/default/2021-05-17/7f0a5358236913593b031b3fdbdd31f8.jpeg",
            link: `activity/read-story-2024/display`,
        })
    },
}
</script>

<style lang="less" scoped>
.rs-display-container {
    .content {
        margin: 0px;
        background-color: #56b0fe;
        padding: 20px;

        .card {
            background-color: white;
            border-radius: 10px;
            padding: 20px;

            .search-bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 10px;
                border: 2px solid #56b0fe;
                overflow: hidden;
                padding: 5px 20px;

                .input {
                    height: 60px;
                    border: none;
                    font-size: 24px;
                    flex: 1;
                    padding: 0;
                    background-color: white;
                }
            }

            .button {
                margin-top: 10px;
                border-radius: 10px;
                box-sizing: border-box;
                background-color: #56b0fe;
                color: white;
                font-size: 28px;
                font-weight: bold;
                width: 100%;
                height: 60px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
            }

            .list {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }
        }
    }

    .area-wrapper {
        margin-top: 10px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        border: 2px solid #56b0fe;
        overflow: hidden;
        height: 60px;

        button {
            border: none;
            padding: 20px;
        }
    }
}
</style>
