<template>
    <div class="video-item-container">
        <img class="cover" :src="item.type === 1 ? item.worksUrl?.coverURL : item.authorPhoto" alt="" />
        <div class="text-warp">
            <div class="title">{{ item.worksName }}</div>
            <div class="author">{{ item.author }}</div>
            <!-- <div class="count-warp">
        <img class="icon" src="../../../../../public/static/images/like2.png" alt="" />
        <div class="count">{{ item.score }}</div>
      </div> -->
            <div class="count-warp">
                <div class="recommend-company">{{ item.recommendCompany }}</div>
            </div>
        </div>
        <div class="no-warp">
            <span>{{ `${item.id}` }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoItem",
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
.video-item-container {
    position: relative;
    margin-bottom: 20px;
    width: calc(50% - 10px);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);

    .cover {
        width: 100%;
        height: 190px;
        object-fit: contain;
    }

    .text-warp {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        padding-bottom: 10px;
    }

    .text-warp div {
        margin-top: 8px;
        overflow: hidden;
    }

    .title {
        width: 100%;
        color: black;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .author {
        padding-top: 10px;
        color: #7c7b66;
        font-size: 24px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .count-warp {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            width: 28px;
            height: 28px;
        }

        .count {
            margin-left: 10px;
            color: #56b0fe;
            font-size: 24px;
            font-weight: bold;
        }

        .recommend-company {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 18px;
            line-height: 24px;
            color: #56b0fe;
        }
    }

    .no-warp {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        background-color: #fefefee8;
        color: red;
        font-size: 24px;
        font-weight: bold;
        padding: 5px 10px;
        min-width: 60px;
        text-align: center;
    }
}

.video-item-container:nth-of-type(2n + 1) {
    margin-right: 19px;
}
</style>
